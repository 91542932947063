<template>
  <div>
    <section>
      <Hero>
        <div class="container">
          <HeadText :titletext="`Bestätigung Ihres Newsletter-Abonnements für ${subsiteDisplayName}`" :subtext="''"></HeadText>
        </div>

        <div class="container mb-5">
          <div class="row justify-content-center pt-sm-4" v-if="this.isRegistrationWaiting">
            <div class="text-center my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong class="ml-2">Ihre Bestätigung wird übernommen...</strong>
            </div>
          </div>
          <div class="row justify-content-center pt-sm-4" v-if="this.getRegistrationErrorStatus">
            <div class="alert alert-warning" role="alert">
              <p>
                {{ this.getRegistrationErrorStatus }}
              </p>
              <p v-if="this.getRegistrationValidationErrors && this.getRegistrationValidationErrors.length > 0">
                {{ this.mapErrors }}
              </p>
            </div>
          </div>
          <div class="row justify-content-center pt-sm-4" v-if="this.isRegistrationSuccess">
            <div class="alert alert-info" role="alert">
              <p>
                Vielen Dank! Ihr Newsletter-Abonnement wurde bestätigt. Sie können Ihre Einstellungen jederzeit über Ihr
                Benutzerprofil ändern.
              </p>
            </div>
          </div>
        </div>
      </Hero>
    </section>
  </div>
</template>

<script>
import Hero from "@/components/HeroPicture";
import HeadText from "@/components/HeadText";
import {mapGetters} from "vuex";

export default {
  name: "ConfirmNewsletterRegistration",
  components: {Hero, HeadText},
  metaInfo() {
    return {
      title: this.getClientConfig('name') + ' | ' + this.getClientConfig('title'),
      meta: [
        {
          name: 'description',
          content: 'Der Marktplatz für geschlossene Fonds. Wir bieten freien und institutionellen Vertriebsgesellschaften das führende Service- und Plattformangebot für die Auswahl, den Vertrieb, die Abwicklung und die Bestandsverwaltung geschlossener Fonds in Deutschland und Österreich.',
        },
        { name: 'robots', content: "noindex, nofollow", vmid: 'robots' },
        { name: 'googlebot', content: "noindex, nofollow, max-snippet:-1, max-image-preview:large, max-video-preview:-1", vmid: 'googlebot' },
        { name: 'bingbot', content: "noindex, nofollow, max-snippet:-1, max-image-preview:large, max-video-preview:-1", vmid: 'bingbot' },
      ],
    }
  },
  data() {
    return {
      error: null,
    }},
  computed: {
    ...mapGetters('registration', ['isRegistrationWaiting','getRegistrationErrorStatus','isRegistrationSuccess',
      'getRegistrationValidationErrors']),
    subsiteDisplayName() {
      return this.isMeinHandel ? 'Mein Handel' : 'Mein Zweitmarkt'
    },
    isMeinHandel() {
      return this.$store.getters["getSubsite"] === 'meinhandel';
    },
    mapErrors() {
      let errors = this.getRegistrationValidationErrors;
      let result = '';
      let firstCode = true
      for (let errorCode of errors) {
        result = result + this.lookupErrorCode( errorCode, firstCode ) + ', ';
        firstCode = false;
      }

      return result.slice(0, result.length - 2);
    },
  },
  methods: {
    getClientConfig(path) {
      return this.$store.getters['clientConfig/config'](path)
    },
    lookupErrorCode( errorCode, firstCode ) {
      if ( 'E4000' === errorCode ) return (firstCode ? 'D' : 'd') + 'ieser Benutzername existiert bereits'
      if ( 'E4001' === errorCode ) return (firstCode ? 'E' : 'e') + 'ine Registrierung für diese E-Mail-Adresse existiert bereits'
      if ( 'E4002' === errorCode ) return (firstCode ? 'U' : 'u') + 'nbekannter Datensatztyp'
      if ( 'E4004' === errorCode ) return (firstCode ? 'U' : 'u') + 'ngültige E-Mail-Adresse'
      if ( 'E4202' === errorCode ) return (firstCode ? 'U' : 'u') + 'ngültiges Passwort'
      if ( 'E4005' === errorCode ) return (firstCode ? 'D' : 'd') + 'ie angegebene E-Mail-Adresse ist zu lang'
      if ( 'E4203' === errorCode ) return '\'Anrede\' muss angegeben werden'
      if ( 'E4021' === errorCode ) return '\'Vorname\' muss angegeben werden'
      if ( 'E4022' === errorCode ) return '\'Nachname\' muss angegeben werden'
      if ( 'E4040' === errorCode ) return '\'Firma\' muss angegeben werden'
      if ( 'E4108' === errorCode ) return '\'Firma\' ist zu lang'
      if ( 'E4109' === errorCode ) return '\'Vorname\' ist zu lang'
      if ( 'E4110' === errorCode ) return '\'Nachname\' ist zu lang'

      if ( 'E4300' === errorCode ) return 'Der Token ist ungültig'
      if ( 'E4301' === errorCode ) return 'Unbekannter Mandant'
      if ( 'E4302' === errorCode ) return 'Die Registrierung ist abgelaufen'
      if ( 'E4303' === errorCode ) return 'Die Registrierung wird bereits bearbeitet'
      if ( 'E5000' === errorCode ) return 'Die Registrierung konnte nicht gespeichert werden'
      if ( 'E5001' === errorCode ) return 'Ihr Zugang wurde angelegt. Daten zur Einstimmung zu den AGB und den Datenschutzbestimmungen konnten nicht gespeichert werden. Bitte setzen Sie sich mit uns in Verbindung.'
      return 'Unbekannter Fehlercode ' + errorCode;
    },
    submitRegistrationConfirmation() {
      this.$store.dispatch('registration/resetState');
      const payload = {
        token : this.$route.query.token,
      }
      this.$store.dispatch('registration/postConfirmNewsletterRegistration',payload);
    },
  },
  beforeRouteEnter(to,from,next) {
    next(vm => {
      vm.$store.dispatch('registration/resetState');
      vm.submitRegistrationConfirmation();
    })
  },
}
</script>

<style scoped>

</style>